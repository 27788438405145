<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="table"
    />

    <div v-else>
      <div class="pa-0 px-1 pb-3 w-full d-flex align-center justify-end">
        <v-dialog
          v-model="isCreating"
          width="500"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              small
              color="primary"
              :ripple="false"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              新規商品タグ
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model="addTarget.name"
                label="名前"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :ripple="false"
                @click="isCreating = false; create()"
              >
                作成
              </v-btn>
              <v-btn
                color="blue darken-1"
                :ripple="false"
                text
                @click="isCreating = false"
              >
                キャンセル
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <v-data-table
        ref="dataTable"
        :key="JSON.stringify(vendibleTags)"
        v-sortable-data-table
        :headers="headers"
        :items="vendibleTags"
        :header-props="{ sortByText: 'ソート' }"
        hide-default-footer
        disable-pagination
        no-data-text="データがありません"
        @sorted="updatePositions($event)"
      >
        <template #[`item.attributes.name`]="{item}">
          <yona-edit-dialog
            :display-name="item.attributes.name"
            @open="edit(item)"
            @save="update"
          >
            <template #input>
              <v-text-field
                v-if="isEditing"
                v-model="editTarget.name"
                placeholder="名前"
              />
            </template>
          </yona-edit-dialog>
        </template>

        <template #[`item.actions`]="{item}">
          <yona-edit-dialog
            title="削除の確認"
            btn-color="error"
            save-text="同意の上で削除"
            @save="destroy(item.id)"
          >
            <template #append-outer-display-name>
              <v-btn
                icon
                :ripple="false"
              >
                <v-icon small>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>

            <template #input>
              <span>
                削除を実行してもよろしいですか
              </span>
            </template>
          </yona-edit-dialog>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  mdiTrashCanOutline,
  mdiPlus,
} from '@mdi/js'
import { map } from 'lodash'
import sortableDataTable from '@/directives/sortableDataTable'
import VendibleTagApi from '@/api/waiter/VendibleTag'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'

export default {
  components: {
    YonaEditDialog,
  },
  directives: {
    sortableDataTable,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const isLoading = ref(false)
    const isCreating = ref(false)
    const isEditing = ref(false)
    const vendibleTags = ref([])
    const editTarget = reactive({ id: null, name: null })
    const addTarget = reactive({ name: '' })

    const edit = item => {
      isEditing.value = true

      editTarget.id = item.id
      editTarget.name = item.attributes.name
    }

    const create = async () => {
      const res = await VendibleTagApi.createVendibleTag({ name: addTarget.name })

      if (res?.data?.data) {
        vendibleTags.value.push(res.data.data)
        vm.$toast.success('商品タグを作成しました')
      }

      isCreating.value = false
      addTarget.name = ''
    }

    const update = async () => {
      const { id, name } = editTarget
      const res = await VendibleTagApi.updateVendibleTag({ id, name })

      if (res?.data?.data) {
        const index = vendibleTags.value.findIndex(o => +o.id === +id)
        vendibleTags.value.splice(index, 1, res.data.data)
        vm.$toast.success('商品タグを更新しました')
      }

      isEditing.value = false
      editTarget.id = null
      editTarget.name = null
    }

    const destroy = async id => {
      const res = await VendibleTagApi.deleteVendibleTag(id)

      if (res.data.status === 'error') {
        vm.$toast.error('削除できません')
        vm.$toast.error(res.data.message.join('\n'))

        return
      }

      if (res) {
        const index = vendibleTags.value.findIndex(o => +o.id === +id)
        vendibleTags.value.splice(index, 1)
        vm.$toast.success('商品タグを削除しました')
      }
    }

    const getVendibleTags = async () => {
      isLoading.value = true

      const res = await VendibleTagApi.getVendibleTags()

      if (res?.data) {
        vendibleTags.value = [...res.data.vendibleTags.data]
      }

      isLoading.value = false
    }

    const updatePositions = async event => {
      const movedVendibleTag = vendibleTags.value.splice(event.oldIndex, 1)[0]
      vendibleTags.value.splice(event.newIndex, 0, movedVendibleTag)
      const idAndPositions = map(vendibleTags.value, (o, idx) => {
        return { id: o.id, position: idx }
      })

      const res = await VendibleTagApi.updatePositions(idAndPositions)
    }

    getVendibleTags()

    return {
      // data
      isLoading,
      isCreating,
      isEditing,
      vendibleTags,
      headers: [
        { text: '名前', value: 'attributes.name' },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
      editTarget,
      addTarget,

      // methods
      edit,
      update,
      destroy,
      create,
      updatePositions,

      icons: {
        mdiTrashCanOutline,
        mdiPlus,
      },
    }
  },
}
</script>
